(function($) {
	$(function() {
		var $window = $(window),
			winHeight = $window.height(),
			transitionDuration = 200,
			$html = $('html'),
			$mainNavBlock = $('#main-nav-block');
		
		jQuery.fn.extend({
			slideDownFadeIn: function(dur, callback) {
				var self = this;
				self.addClass('slideDownFadeIn');
		
				self.slideDown(dur, function() {
					self.addClass('shown');
					setTimeout(function() {
						self.removeClass('slideDownFadeIn shown');
						if (callback) {
							callback.call(self);
						}
					}, dur);
				});
				
				return self;
			},
			fadeOutSlideUp: function(dur, callback) {
				var self = this;
				self.addClass('shown fadeOutSlideUp');
				self.removeClass('shown');
				setTimeout(function() {
					self.slideUp(dur, function() {
						self.removeClass('fadeOutSlideUp');
						if (callback) {
							callback.call(self);
						}
					});
				}, dur);
				return self;
			}
		});

		var hideOneShowOther = function(willBeHidden, willBeShown, callback) {
			var willBeShownBlockHeight = willBeShown.css({'visibility':'hidden', 'display':'block'}).height();
			willBeShown.css({'display':'none', 'visibility':'visible'});
			// var willBeShownBlockHeight = willBeShown.height();
			var willBeHiddenBlockHeight = willBeHidden.height() || 0,
			    minHeightedBlock = willBeShown.parent();

			if (willBeShownBlockHeight > willBeHiddenBlockHeight) {
				// console.log('>',willBeShownBlockHeight)
				minHeightedBlock.css('min-height', minHeightedBlock.outerHeight());
				willBeHidden.fadeOut(transitionDuration, function() {
					willBeHidden.removeClass('active');
					willBeShown.addClass('slideDownFadeIn active').slideDownFadeIn(transitionDuration, function() {
						// willBeShown.addClass('shown');
						setTimeout(function() {
							willBeShown.removeClass('slideDownFadeIn shown');
							minHeightedBlock.css('min-height', 'auto');//in case of wrong calculations
						}, transitionDuration);

						if (callback) {
							callback();
						}
					});
				});
			} else if (willBeShownBlockHeight < willBeHiddenBlockHeight) {
				// console.log('<',willBeShownBlockHeight)
				minHeightedBlock.css('min-height', minHeightedBlock.outerHeight() - minHeightedBlock.height() + willBeShownBlockHeight);
				willBeHidden.addClass('slideDownFadeIn shown').fadeOutSlideUp(transitionDuration, function() {
					willBeHidden.removeClass('active slideDownFadeIn');
					willBeShown.fadeTo(transitionDuration, 1, function() {//fadeTo instead of fadeIn, because of Safary's blinking =/
						willBeShown.addClass('active').css('opacity', '');//opacity, '' - because of fadeTo
						minHeightedBlock.css('min-height', 'auto');//in case of wrong calculations

						if (callback) {
							callback();
						}
					});
				});
			} else {
				// console.log('=')
				minHeightedBlock.css('min-height', minHeightedBlock.outerHeight());
				willBeHidden.fadeOut(transitionDuration, function() {
					willBeHidden.removeClass('active');
					willBeShown.fadeTo(transitionDuration, 1, function() {//fadeTo instead of fadeIn, because of Safary's blinking =/
						// willBeShown.addClass('shown');
						willBeShown.addClass('active').css('opacity', '');//opacity, '' - because of fadeTo
						// willBeShown.removeClass('slideDownFadeIn shown');
						if (callback) {
							callback();
						}
					});
				});
			}
			
			// minHeightedBlock.css('min-height', minHeightedBlock.outerHeight() - minHeightedBlock.height() + willBeShownBlockHeight);
			// willBeHidden.fadeOutSlideUp(transitionDuration, function() {
			// 	willBeShown.fadeIn(transitionDuration, function() {
			// 		if (callback) {
			// 			callback();
			// 		}
			// 	});
			// });
		}
	
		$('.with-label').each(function() { //In case some info after refresh
			var $this = $(this);

			if ($this.val()) {
				$this.parent().addClass('entered');
			}

		});

		$('.with-label').on('change', function() {
			var $this = $(this);

			if ($this.val()) {
				$this.parent().addClass('entered');
			} else {
				$this.parent().removeClass('entered');
			}

		});

		var parallaxBlock = $('.parallax-block');
		parallaxBlock.each(function() {
			var item = $(this),
			    parallaxBlockHeight = item.outerHeight(),
			    parallaxStart = item.offset().top - winHeight,
			    parallaxEnd = parallaxStart + winHeight + parallaxBlockHeight;
			//proceedParallax(item);
			$window.on('scroll.offices', function() {
				proceedParallax(item, parallaxBlockHeight, parallaxStart, parallaxEnd);
			});
		});

		function proceedParallax(item, parallaxBlockHeight, parallaxStart, parallaxEnd) {
			var winScroll = $window.scrollTop();

			if (winScroll > parallaxStart && winScroll < parallaxEnd) {

				if (item.hasClass('no-parallax-top')) {
					item.removeClass('no-parallax-top');//To fit bckg-image to top (not e.g. 0.746494px)
				}

				item.css({
					'background-position' : '50% ' + (winScroll - parallaxStart)/(parallaxEnd - parallaxStart)*100 + '%'
				});
			} else if (winScroll <= parallaxStart) {

				if (!item.hasClass('no-parallax-top')) {
					item.addClass('no-parallax-top');
				}
			} else {
				//console.log('finish');
			}
		}

		//Tabs
		var $tabs = $('.dc-tabs');
		$tabs.each(function() {
			var $tab = $(this),
			    tabContentId,
			    $tabsContent = (tabContentId = $tab.data('tabs-content')) ? $('#' + tabContentId) : $tab.next();

			$tab.find('a').on('keypress click', function(e) {
				if (e.which === 13 || e.type === 'click') {
					e.preventDefault();
					var $this = $(this);

					if (!$this.hasClass('active')) {
						var $activeTab = $tabsContent.children('.active'),
						    $openingTab = $tabsContent.find($this.attr('href'));
						hideOneShowOther($activeTab, $openingTab, function() {
							$tab.find('.active').removeClass('active');
							$this.addClass('active');
							$tabsContent.removeClass($activeTab[0].id + '-active').addClass($openingTab[0].id + '-active');//Need for Reports block on MT4 tab on Login page
							$tab.trigger('dc.tabSwitched', [$this, $openingTab]);
						});

						if ($this.data('hash')) {
							if (history.replaceState) {
								history.replaceState('', '', $this.data('hash'));
							} else {
								window.location.hash = $this.data('hash');
							}
						}
					}
				}
			});
		});

		function Form($form, options) {

			this._$form = $form;
			this.name = this._$form.data('name') || 'demo';//because demo forms are the most important
			var self = this,
			    defaults = {
			    	setAutoFocus: false,
			    	multySteps: false
			    };

			this.init = function(callback) {
				options = $.extend(defaults, options);

				this.$contentBlock = this._$form.find('.content-block');
				this.$successBlock = this._$form.find('.success-block');
				this.$errorBlock = this._$form.find('.error-block');
				this.$apiErrorBlock = this._$form.find('.api-error-block');
				this.$requiredFields = this._$form.find('.required');
				this.$requiredCheckboxes = this._$form.find('.required-checkbox').each(function() {
					var $this = $(this).on('change', function() {
						checkRequieredCheckboxes();
					});
				});

				function checkRequieredCheckboxes() {
					var requiredCheckboxesAreChecked = true;
					self.$requiredCheckboxes.each(function() {
						var $this = $(this);
					
						if (!$this.is(':checked')) {
							self._$form.addClass('inactive');
							requiredCheckboxesAreChecked = false;
							return false;
						}

					});

					if (requiredCheckboxesAreChecked) {
						self._$form.removeClass('inactive');
					}

				}

				checkRequieredCheckboxes();

				this.getSettings(function() {
					// self.$requiredFields = self._$form.find('.required');
					self.fillForm(self.settings);

					if (options.setAutoFocus) {
						self.setAutoFocus($('#' + self._$form.data('autofocus-field-id')));
					}

					if (callback) {
						callback();
					}

					if (self.afterInit) {
						self.afterInit();
					}

					// console.log(this);

				});
			}

			this.getSettings = function(callback) {
				// console.log('asked for settings');
				$.ajax({
					url: $form.data('api'),
					dataType: 'json',
					complete: function() {
						$form.removeClass('receiving-data');
					},
					success: function(res) {
						// console.log(res);
						self.settings = res;

						if (callback) {
							callback();
						}

					},
					error: function(e) {
						// console.log('did not get settings');
						self._$form.addClass('api-error');
						hideOneShowOther(self.$contentBlock, self.$apiErrorBlock);
					}
				});
			}

			this.showCaptcha = function(focus) {
				var $captchaBlock = this._$form.find('.captcha-block');
				this.$captchaInput = $captchaBlock.find('.captchaPhrase');
				this.$captchaIdInput = $captchaBlock.find('.captcha-id');
				this.$captchaImg = this._$form.find('.captcha-img');
				this.$captchaInput.addClass('required');
				this.$requiredFields = this.$requiredFields.add(this.$captchaInput);
				this.captchaShown = true;
				this.generateCaptcha();

				this.$captchaImg.on('click', function() {
					self.generateCaptcha();
				});

				$captchaBlock.slideDownFadeIn(transitionDuration, function() {

					if (focus) {
						this.$captchaInput.focus();
					}
					
				});
			}

			this.generateCaptcha = function() {
				$.ajax({
					url: self.$captchaImg.data('src'),
					dataType: 'json',
					method: 'GET',
					success: function(res) {
						self.$captchaIdInput.val(res.meta.captchaUid);
						self.$captchaImg.attr('src', 'data:' + res.meta.type + ';' + res.meta.encode + ',' + res.meta.image);
						self.$captchaInput.val('');
					},
					error: function(res) {
						self._$form.addClass('api-error');
						hideOneShowOther(self.$contentBlock, self.$apiErrorBlock);
						// console.log('Something with captcha (request)');
					}
				});
			}

			this.fillForm = function(settings) {
				// console.log('started filling form');
				this.selects = this._$form.data('selects') || this.selects;
				this.radios = this._$form.data('radios') || this.radios;
				this.inputs = this._$form.data('inputs');

				if (this.selects) {
					this.fillSelects(this.selects, settings);
				}

				if (this.radios) {
					this.fillRadios(this.radios, settings);
				}

				if (this.inputs) {
					this.fillInputs(this.inputs, settings);
				}

				if (this.settings.isCaptchaRequired) {
					this.showCaptcha();
				}
				// console.log('ended filling form');
			}

			this.fillSelects = function(selectsObject, settings) {
				var callback;

				$.each(selectsObject, function(key, selectId) {
					callback = settings[key + 'Callback'] || key + 'Callback';
					self.fillSelect($('#' + selectId), settings[key], settings[key + 'Def'], callback);
				});
			}

			this.fillSelect = function($select, data, defVal, callback) {
				var strToInsert;

				$.each(data, function(value, text) {

					if ($.isArray(data)) {
						value = text;
					}

					strToInsert += '<option value="' + value + '"' + ((defVal === value) ? ' selected' : '') + ' data-value="' + value + '">' + text + '</option>';
				});

				$select.empty().append(strToInsert).data('default-value', defVal);

				if (callbacks[callback]) {
					callbacks[callback].call(this, $select);
				}

				$select.trigger('chosen:updated');
			}

			this.fillRadios = function(radiosObject, settings) {
				$.each(radiosObject, function(key, radioBlockId) {
					self.fillRadio($('#' + radioBlockId), settings[key], settings[key + 'Def']);
				});
			}

			this.fillRadio = function($radioBlock, data, defVal) {
				var radioItemTemplate,
					radioItem;

				radioItemTemplate = $radioBlock.children();
				$radioBlock.empty();

				$.each(data, function(value, text) {

					if ($.isArray(data)) {
						value = text;
					}

					radioItem = radioItemTemplate.clone();
					var curRadioItemInput = radioItem.find('input');

					curRadioItemInput.val(value).attr({
						'id': curRadioItemInput.attr('id') + value,
						'class': curRadioItemInput.attr('class') + value
					});

					if (value === defVal) {
						curRadioItemInput.prop('checked', true);
					}

					var curRadioItemLabel = radioItem.find('label');
					curRadioItemLabel.attr('for', curRadioItemLabel.attr('for') + value)
									 .find('.text')
									 .html(curRadioItemLabel.data('text')//html - because of lp pamm callback form (in ru I get html entities)
									 .replace('%text%', text));

					$radioBlock.append(radioItem);
				});
			}
			
			this.fillInputs = function(inputsObject, settings) {
				// var callback;

				$.each(inputsObject, function(key, inputId) {
					// callback = key + 'Callback';
					self.fillInput($('#' + inputId), settings[key + 'Def']);
				});
			}

			this.fillInput = function($input, defVal) {
				$input.val(defVal)
					  .trigger('change')
					  .attr('data-value', defVal);
			}

			this.setOption = function(key, value) {
				options[key] = value;
			}

			this.setAutoFocus = function(field) {
				var fieldPos = field.offset().top,
				    fieldPosBot = fieldPos + field.outerHeight(),
				// winHeight = $window.height(),
				    winScroll = $window.scrollTop();

				if (winHeight + winScroll > fieldPosBot) {
					
					if (winScroll < fieldPos) {
						field.focus();
					} else {
						$window.on('scroll.fldfocus', function() {
							winScroll = $window.scrollTop();
							if (winScroll < fieldPos) {
								field.focus();
								$window.off('scroll.fldfocus');
							}
						});
					}
				} else {
					$window.on('scroll.fldfocus', function () {
						winScroll = $window.scrollTop();
					
						if (winHeight + winScroll > fieldPosBot && winScroll < fieldPos) {
							field.focus();
							$window.off('scroll.fldfocus');
						}
					});
				}
			}

			this.initSteps = function() {
				this.step = 0;
				this.$stepBlocks = this._$form.find('.form-step');
				this.stepsNumber = this.$stepBlocks.length;
				this.currentStepBlock = this.$stepBlocks.eq(this.step);
				this.tabbedSteps = this.currentStepBlock.hasClass('dc-tab-content');//In case of switching is possible by clicable tabs

				if (this.tabbedSteps) {
					this.$stepBlocks.each(function() {

						if (self.$tabs) {
							self.$tabs = self.$tabs.add($('#' + $(this).data('tab-id')));
						} else {
							self.$tabs = $('#' + $(this).data('tab-id'));
						}
					});
				}
				//добавить сюда массив с табами, чтобы кликать быстро
			}

			this.nextStep = function() {

				if (this.step === undefined) {
					this.initSteps();
				}

				this.synchronizeWithTabs();
				this.toStep(this.step + 1);
			}

			this.synchronizeWithTabs = function() {

				if (this.tabbedSteps && !this.currentStepBlock.hasClass('active')) {//means that user clicked tabs
					this.currentStepBlock = this.$stepBlocks.filter('.active');
					this.step = this.$stepBlocks.index(this.currentStepBlock);
				}
			}

			this.toStep = function(stepToShowNumber) {

				if (stepToShowNumber < this.stepsNumber) {
					var stepToShowBlock = this.$stepBlocks.eq(stepToShowNumber);

					if (this.tabbedSteps) {
						this.$tabs.eq(stepToShowNumber).click();//to show new styles for tabs
					} else {
						hideOneShowOther(this.currentStepBlock, stepToShowBlock);
						//...
					}

					this.step = stepToShowNumber;
					this.currentStepBlock = stepToShowBlock;
				} else {
					this.send();
				}
			}

			this._$form.on('submit', function(e) {
				e.preventDefault();
				self.validate(true);
				
				if (self.isValid) {

					if (options.multySteps) {
						self.nextStep();
					} else {
						self.send();
					}

				}

			});

			this.validate = function(afterFormSubmit) {
				this.isValid = true;
				this.$requiredFields.each(function() {
					var $this = $(this);

					if (afterFormSubmit || !$this.hasClass('had-api-error')) {
						self.removeErrorFromField($this);
					}

					if (!$.trim($this.val())) {

						if (!self.checkNamespacedEvents($this, 'validate', ['change'])) {//not to be triggered many times
							$this.on('change.validate', function() {
								self.validate(false);
							});
						}

						self.addErrorToField($this, $this.data('error-empty'), afterFormSubmit);
						self.isValid = false;
					}
				});

				this.$requiredCheckboxes.each(function() {
					var $this = $(this);
					
					if (!$this.is(':checked')) {
						self.isValid = false;
						return false;
					}

				});

				if (this.isValid) {//For the next case: one of required inputs is filled, others - no. User try to submit form. Empty fields became errored. And if now user delete text from filled input, it will remain withot error. With this code it will became errored
					this.$requiredFields.each(function() {
						var $this = $(this);

						if (!self.checkNamespacedEvents($this, 'validate', ['change'])) {//not to be triggered many times
							$this.on('change.validate', function() {
								self.validate(false);
							});
						}

					});
				}

			}

			this.checkNamespacedEvents = function($obj, namespace, eventsToCheck) {
				var eventsObj = $._data($obj[0], 'events'),
				    eventsExist = false;
				$.each(eventsObj, function() {
					$.each(this, function() {

						if (eventsToCheck && eventsToCheck.indexOf(this.type) != -1 && this.namespace === namespace) {
							eventsExist = true;
						}

					});
				});
				return eventsExist;
			}

			this.addErrorToField = function($field, errorText, afterFormSubmit) {
				var $errorBlock = $field.parents('.error-parent');

				$errorBlock.addClass('has-error');
				$errorBlock.find('.error-text').text(errorText);

				if (afterFormSubmit) {
					self._$form.find('.has-error').eq(0).find('input').focus();
				}

			}

			this.removeErrorFromField = function($field) {
				$field.parents('.error-parent').removeClass('has-error');
			}

			function getIsoTimeZoneOffset(offset) {
				var sign = (offset >= 0) ? '+' : '-';
				offset = Math.abs(offset);
				return sign + format(offset / 60) + ':' + format(offset % 60);

				function format(number) {
					var norm = Math.floor(number);
					return (norm < 10 ? '0' : '') + norm;
				}
			}
			
			var $fullPhoneInput,
			    $gmtInput;

			this.send = function() {
				var self = this;

				if ($fullPhoneInput === undefined) {//to do it once
					$fullPhoneInput = this._$form.find('.full-phone-input');
				}

				if ($fullPhoneInput.length) {
					$fullPhoneInput.val($('#' + $fullPhoneInput.data('code-id')).val() + $('#' + $fullPhoneInput.data('number-id')).val());
				}

				if ($gmtInput === undefined) {//to do it once
					$gmtInput = this._$form.find('#' + self.name + '-gmt');
					
					if ($gmtInput.length) {
						$gmtInput.val(getIsoTimeZoneOffset(-(new Date).getTimezoneOffset()));
					}
					
				}

				if (this.status !== 'sending') {//for preventing numerous sendings
					this.status = 'sending';
					this._$form.addClass('sending');

					if (this.$errorBottomBlock) {
						this.$errorBottomBlock.fadeOutSlideUp(transitionDuration*2, function() {
							self.$errorBottomBlockText.empty();
							sendRequest();
						});
					} else {
						sendRequest();
					}

					function sendRequest() {

						var suffix = '';

						if(document.location.href.indexOf('jp') > -1) {
							alert('JP detected');
							// if($('#demo-mt4')){
							// 	if($('#demo-mt4').val() == 1 ){
							// 		suffix  = '&mt4Currency=' + $('#demo-traderCurrency').val();
							// 		suffix += '&mt4Balance=' + $('#demo-traderBalance').val();
							// 	}
							// }
						}
						// alert(self._$form.serialize() + suffix); return false;

						$.ajax({
							url: self._$form.data('handler'),
							method: 'POST',
							dataType: 'json',
							data: self._$form.serialize() + suffix,
							xhrFields: {
								withCredentials: true
							},
							complete: function() {
								self._$form.removeClass('sending');
								self.status = 'sent';
							},
							success: function(e) {
								self.success(e);
								// self.error(e);
							},
							error: function(e) {
								// console.log('gogo')
								// self.success(e);
								// e = {
								// 	responseJSON: {
								// 		errors:
								// 			[
								// 				{
								// 					status: 400,
								// 					code: 146819,
								// 					title: 'Извините, техническая ошибка базы данных. Пожалуйста, обратитесь в службу поддержки, указав следующий код ошибки: 14782389'
								// 				}
								// 			]
								// 	}
								// };
								self.error(e);
							}
						});
					}
				}

			}

			this.iconedFormPrepare = function(result) {
				
				
			}

			this.iconedFormResultAnimate = function(result) {//animation after successful or error sending
				var $actualBlock;
				this.$iconedElement = self._$form.parents('.iconed-element');
				
				if (this.$iconedElement.length) {

					if (result === 'success') {
						$actualBlock = self.$successBlock;
					} else {
						$actualBlock = self.$errorBlock;
					}

					this.$iconedElement.on('popup.closed', function() {
						self.$iconedElement.removeClass(result + ' pre-success');
						hideOneShowOther($actualBlock, self.$contentBlock);
					});

					this.$iconedElement.addClass('pre-success');
					setTimeout(function() {self.$iconedElement.addClass(result)}, transitionDuration);
				}

			}

			this.success = function(callback) {

				hideOneShowOther(self.$contentBlock, self.$successBlock, function() {

					self.iconedFormResultAnimate('success');

					if (callback) {
						callback();
					}

				});
			}

			this.error = function(e) {

				if (e.responseJSON && e.responseJSON.errors && e.responseJSON.errors.length) {
					$.each(e.responseJSON.errors, function() {
						var $errorField = self._$form.find($('#' + self.name + '-' + this.source));

						if ($errorField.length) {

							if (this.source == 'captchaPhrase') {
								if (self.captchaShown) {
									self.generateCaptcha();

								} else {
									self.showCaptcha(true);
								}
							}

							if (options.multySteps) {
							
								if ($errorField.is(':visible')) {
									self.addErrorToField($errorField, $errorField.data('error-invalid'), true);
								} else {
									self.toStep(self.$stepBlocks.index($errorField.parents('.form-step')));
									self.addErrorToField($errorField, $errorField.data('error-invalid'), true);
								}
								
							} else {
								self.addErrorToField($errorField, $errorField.data('error-invalid'), true);
							}

							if (!$errorField.hasClass('had-api-error')) {
								$errorField.addClass('had-api-error');//Not to remove error info after changing field value
							}

						} else {
							
							if (this.title) {
								self.$errorBottomBlock = self._$form.find('.bottom-error-block');
								self.$errorBottomBlockText = self.$errorBottomBlock.find('.text');
									// console.log($errorBottomBlock,$errorTextBlock)
								self.$errorBottomBlockText.text(this.title);
								self.$errorBottomBlock.slideDownFadeIn(transitionDuration*2);
								// self.addCommonError(this.title);
							} else {
								self.showCommonApiError();
							}

						}

					});
				} else {
					this.showCommonApiError();
				}
			}

			this.showCommonApiError = function() {
				self._$form.addClass('api-error');
				hideOneShowOther(self.$contentBlock, self.$errorBlock, function() {
					self.iconedFormResultAnimate('error');
				});
			}
		}

		function DemoForm($form, options) {
			Form.apply(this, arguments);

			var self = this;

			this.$accTypeCheckboxes = $form.find('#demo-acc-types').find('.acc-type-checkbox')
				.on('click', function(e) {
					var nothingChecked = true;
					self.$accTypeCheckboxes.each(function() {

						if ($(this).is(':checked')) {
							nothingChecked = false;
							return false;
						} else {
							
							if ($(this).is('[readonly]')) {//for form, embed in mobile JForex app (I add readonly attribute to #demo-jforex checkbox)
								return false;
							}

						}

					});

					if (nothingChecked) {
						e.preventDefault();
					}

				})
				.on('change', function(e) {
					triggerAccTypeCheckbox($(this));
				});

			function triggerAccTypeCheckbox($checkbox) {

				if ($checkbox.val()) {
					$checkbox.val('');
					$form.removeClass($checkbox.data('acc-sett-vis-class'));
				} else {
					$checkbox.val('1');
					$form.addClass($checkbox.data('acc-sett-vis-class'));
				}
			}

			this.$accTypeCheckboxes.each(function() {//fix for FF after refreshing page if value of checkbox doesn't match to checked state
				var $this = $(this);

				if ($this.is(':checked')) {
					if (!$this.val()) {
						triggerAccTypeCheckbox($this);
					}
				} else {
					if ($this.val()) {
						triggerAccTypeCheckbox($this);
					}
				}
			});

			this.formatSettings = function() {
				// console.log('country',country);
				var country = {};

				$.each(this.settings.meta.countries, function(key, value) {
					country[key] = value.callingCode + ' ' + value.name;
					// console.log(key, value);
				});
				// this.settings.meta.general.defaultCountry = 'US';

				this.formatedSettings = {//prepare them to standart fill... methods
					country: country,
					countryDef: (this.settings.meta.defaultCountry) ? ((self.settings.meta.bannedCountryList.indexOf(this.settings.meta.defaultCountry) != -1) ? 'CH': this.settings.meta.defaultCountry) : 'CH',//if no default country in settings - set CH; if default country in settings is in banned list - set CH (to show user notification, when he chose his country. Otherwise, he would see notification on every page (in quick demo))
					countryCallback: 'demoCountryCallback'
				}
			}

			var parentFillForm = this.fillForm;

			this.fillForm = function() {
				this.selects = $form.data('common-selects') || $form.data('selects');//{'name in api': 'select id'}. 'common-selects' - for main demo forms; 'selects' - for quick
				this.radios = {};

				this.formatSettings();//api json is too compicated, so we need to format selects data to common view

				parentFillForm.call(this, this.formatedSettings);

				if (this.settings.meta.isCaptchaRequired) {
					this.showCaptcha(false);
				}
				
			}

			var parentValidate = this.validate;

			this.validate = function(afterFormSubmit) {
				parentValidate.call(this, afterFormSubmit)

				if (!this.isValid) {

					if (self._$form.hasClass('validated')) {//for availability of tabs
						self._$form.removeClass('validated');
					}

				} else {

					if (!self._$form.hasClass('validated')) {
						self._$form.addClass('validated');
					}

				}

			}

			// this.checkMobForSuccess = function() {
			// 	var mobile = jscd.checkMob();

			// 	if (mobile) {
			// 		this.$successBlock.addClass('mobile');

			// 		if (jscd.checkIPad()) {
			// 			this.$successBlock.addClass('ipad');
			// 		}
			// 	} else {
			// 		var os = jscd.getOs(),
			// 		    osBits = jscd.getBits(),
			// 		    installDefLink = this.$successBlock.find('#install-jf-def-link'),
			// 		    correctDefLink = this.$successBlock.find('.' + os.toLowerCase() + '-' + osBits);
			// 		installDefLink.text(correctDefLink.text()).attr('href', correctDefLink.attr('href'));
			// 		correctDefLink.remove();
			// 	}
			// }

			this.replaceEmail = function() {
				this.$successBlock.find('.main-text').html(function(index, oldhtml) {
					return oldhtml.replace('%email%', '<span class="email">' + self._$form.find('[type="email"]').val() + '</span>')
				});
			}

			var parentSuccess = this.success;

			this.success = function(e) {
				// this.checkMobForSuccess();
				this.replaceEmail();
				parentSuccess.call(this);
				self.googleAnalitics();
			}
			
			this.googleAnalitics = function() {
				var demoType = '';
				this.$accTypeCheckboxes.each(function() {
					var $this = $(this);

					if ($this.is(':checked')) {
						demoType += $this.data('ga-id');
					}

				});
				dataLayer.push({
					'event': self.$successBlock.data('gtm-event'),
					'eventCategory': self.$successBlock.data('gtm-event-category'),
					'demoType': demoType
				});

				//Old system. Must be removed
				// var ifrCont = document.createElement("iframe");
				// ifrCont.src = this.$successBlock.data('success-iframe');
				// document.body.appendChild(ifrCont);
				// hideIframe(ifrCont);
				// // прячем фрейм
				// function hideIframe(iframeNode) {
				// 	iframeNode.style.position = 'absolute';
				// 	iframeNode.style.left = '0px';
				// 	iframeNode.style.top = '0px';
				// 	iframeNode.style.height = '1px';
				// 	iframeNode.style.width = '1px';
				// 	iframeNode.style.visibility = 'hidden';
				// }
			}
		}

		function PammMainDemoForm($form, options) {
			DemoForm.apply(this, arguments);
			
			var self = this;
			var parentFormatSettins = this.formatSettings;
			
			this.formatSettings = function() {
				parentFormatSettins.call(this);

				this.formatedSettings.language = this.settings.meta.languages;
				this.formatedSettings.languageDef = this.settings.meta.defaultLanguage;
				this.balanceSelects = [];
				this.currencySelects = [];
				// console.log(this.formatedSettings)
			}

			this.formatBalances = function($balanceSelect, currencySelectVal) {
				var coef = 1;

				if (self.settings.meta.multipliers.hasOwnProperty(currencySelectVal)) {
					coef = self.settings.meta.multipliers[currencySelectVal];
				}

				var balanceOptions = $balanceSelect.find('option');

				balanceOptions.each(function() {
					var $this = $(this),
					    currentValue = $this.data('value') * coef;

					$this.html('<span class="ltr">' + self.formatBalance(currentValue) + '</span>').val(currentValue);
				});

				$balanceSelect.trigger('chosen:updated');
			}

			this.formatBalance = function(val) {
				var separator;

				if (this.formatedSettings.languageDef === 'ja') {
					separator = ',';
				} else {
					separator = "'";
				}

				return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
			}

			this.createCurrencyCallback = function(namePrefix) {
				callbacks[namePrefix + 'CurrencyCallback'] = function($currencySelect) {
					self.currencySelects.push($currencySelect);
					var $balanceSelect = $('#' + $currencySelect.data('balance-id'));

					if ($balanceSelect.length) {
						self.balanceSelects.push($balanceSelect);
						self.formatBalances($balanceSelect, self.formatedSettings[namePrefix + 'CurrencyDef']);

						$currencySelect.on('change', function() {
							self.formatBalances($balanceSelect, $currencySelect.val());
						});

						$balanceSelect.on('change', function() {
							
							if (!$balanceSelect.wasChangedByUser) {
								$balanceSelect.wasChangedByUser = true;
							}

						});
					}

				}
			}

			this.afterInit = function() {
				var self = this,
				    $demoDefSetCheckbox = this._$form.find('#demo-def-set'),
				    pdBtnBlock = this._$form.find('#pd-footer-block'),
				    pdBtnBlockDisclaimer = pdBtnBlock.find('.disclaimer-block'),
				    btnTextBlock = this._$form.find('#btn-text-block');

				if ($demoDefSetCheckbox.length) {//In Japanese main demo form there is only one step
					
					if (!$demoDefSetCheckbox.is(':checked')) {
						checkFirstStep();
					}

					$demoDefSetCheckbox.on('change', function() {
						checkFirstStep();
					});

					function checkFirstStep() {

						if ($demoDefSetCheckbox.is(':checked')) {
							self.setOption('multySteps', false);
							pdBtnBlockDisclaimer.slideDownFadeIn(transitionDuration);
							hideOneShowOther(btnTextBlock.find('.next'), btnTextBlock.find('.submit'));
							self._$form.removeClass('custom-acc-settings');
						} else {
							self.setOption('multySteps', true);//if true, submit will send to next step
							pdBtnBlockDisclaimer.fadeOutSlideUp(transitionDuration);
							hideOneShowOther(btnTextBlock.find('.submit'), btnTextBlock.find('.next'));
							self._$form.addClass('custom-acc-settings');
						}

					}

				}

			}
		}

		function MainDemoForm($form, options) {
			PammMainDemoForm.apply(this, arguments);

			this.accTypes = $form.data('account-types').split(',');

			var self = this;

			var parentFormatSettins = this.formatSettings;

			this.formatSettings = function() {
				
				parentFormatSettins.call(this);

				var accTypesSelectsTypes = (accTypesSelectsTypes = $form.data('account-types-selects')) ? accTypesSelectsTypes.split(',') : [],
				    accTypesRadiosTypes = (accTypesRadiosTypes = $form.data('account-types-radios')) ? accTypesRadiosTypes.split(',') : [];
				$.each(self.accTypes, function(numb, accType) {//adding balances and currencies to formatedSettings
					
					$.each(accTypesSelectsTypes, function(numb, selectType) {//balance,currency - depend on api settings (see "key + 'List'")
						// console.log(accType, selectType);
						self.selects[accType + selectType] = 'demo-' + accType + selectType;
						self.formatedSettings[accType + selectType] = self.settings.meta.accounts[accType][selectType.toLowerCase() + 'List'];
						self.formatedSettings[accType + selectType + 'Def'] = self.settings.meta.accounts[accType]['default' + selectType];

						if (selectType == 'Balance' && self.settings.meta.multipliers.hasOwnProperty(self.settings.meta.accounts[accType]['defaultCurrency'])) {//we get default balance multiplied. To fill select with defaul fillSelects method with USD equivalent values and select correct default value we demultiply it
							self.formatedSettings[accType + selectType + 'Def'] = self.formatedSettings[accType + selectType + 'Def'] / self.settings.meta.multipliers[self.settings.meta.accounts[accType]['defaultCurrency']];
						} else if (selectType == 'Currency') {
							self.formatedSettings[accType + selectType + 'Callback'] = accType + selectType + 'Callback';
							self.createCurrencyCallback(accType);
						}

					});

					$.each(accTypesRadiosTypes, function(numb, radioType) {//leverage
						var accTypeRadioValuesList;

						if (accTypeRadioValuesList = self.settings.meta.accounts[accType][radioType + 'List']) {
							self.radios[accType + radioType] = 'demo-' + accType + '-' + radioType;
							self.formatedSettings[accType + radioType] = accTypeRadioValuesList;
							self.formatedSettings[accType + radioType + 'Def'] = self.settings.meta.accounts[accType]['default' + radioType[0].toUpperCase() + radioType.slice(1)];
						}

					});
				});
				// console.log(this.formatedSettings)
				// console.log('ended formatting settings',this.formatedSettings);
			}

			this.setBalance = function($balanceSelect, $currencySelect, value) {//value - in USD equivalent
				var currency = $currencySelect.val();
				this.formatBalances($balanceSelect, 'USD');
				$balanceSelect.val(value);
				this.formatBalances($balanceSelect, currency);
			}

			this.replaceLoginPass = function(login, password) {
				var $loginPass = this.$successBlock.find('.login-pass');
				// console.log($loginPass, $loginPass.attr('href'));
				$loginPass.attr('href', $loginPass.attr('href').replace('%login%', login).replace('%password%', password));
			}

			var parentSuccess = this.success;

			this.success = function(e) {
				// this.checkMobForSuccess();
				this.replaceEmail();

				if ($('html').hasClass('embed')) {
					this.replaceLoginPass(e.data[0].login, e.data[0].password);
				}

				var h1Block = $('#h1-block'),
				    leftColSuccessBlock = $('#left-col-success-block');

				// var formMainBlockNewHeight = formMainBlock.outerHeight() - formMainBlock.height() + demoSuccessBlockHeight;
				hideOneShowOther(h1Block, leftColSuccessBlock, function() {
					var mainNavBlockOuterHeight;

					if ($mainNavBlock.length) {
						mainNavBlockOuterHeight = $mainNavBlock.outerHeight();
					} else {
						mainNavBlockOuterHeight = 0;
					}

					$html.animate({scrollTop: self._$form.parent().offset().top - 30 - mainNavBlockOuterHeight}, transitionDuration);
				});

				this.$accTypeCheckboxes.each(function() {
					var $this = $(this);

					if ($this.val()) {
						leftColSuccessBlock.addClass($this.data('acc-sett-vis-class'));
					}
					
				});

				this._$form.addClass('successfully-sent');//for progress-bar
				this.$successBlock.find('.last-step-link').on('click', function() {
					self._$form.addClass('last-step-done');//for progress-bar
				});

				parentSuccess.call(this);
			}
		}

		var $mainDemoForm = $('#main-demo-form');

		if ($mainDemoForm.length) {
			var mainDemoForm = new MainDemoForm($mainDemoForm, {
				setAutoFocus: true
			});

			mainDemoForm.init(function() {

				mainDemoForm._$form.addClass('settings-got');//for progress bar

				var $demoBigBalanceCheckbox = $mainDemoForm.find('#demo-big-balance');

				$demoBigBalanceCheckbox.on('change', function() {
					setCorrectBalances();
				});

				if ($demoBigBalanceCheckbox.is(':checked')) {//For reloaded page in FF
					setCorrectBalances();
				}

				function setCorrectBalances() {

					if ($demoBigBalanceCheckbox.is(':checked')) {
						$.each(mainDemoForm.balanceSelects, function(i, $item) {

							if ($item.val() < 50000 && !$item.wasChangedByUser) {
								mainDemoForm.setBalance(mainDemoForm.balanceSelects[i], mainDemoForm.currencySelects[i], 50000);
							}
							
						});
					} else {
						$.each(mainDemoForm.balanceSelects, function(i, $item) {

							if (!$item.wasChangedByUser) {
								mainDemoForm.setBalance(mainDemoForm.balanceSelects[i], mainDemoForm.currencySelects[i], $item.data('default-value'));
							}

						});
					}
					
				}
			});
		}

		function QuickDemoForm($form, options) {
			DemoForm.apply(this, arguments);
		}

		var $quickDemoForm = $('#quick-demo-form');

		if ($quickDemoForm.length) {
			var quickDemoForm = new QuickDemoForm($quickDemoForm, {
				setAutoFocus: true
			});
			quickDemoForm.init(function(){
				// console.log('hohoh')
			});
		}

		var $sendUsMsgForm = $('#send-us-msg-form');

		if ($sendUsMsgForm.length) {
			var sendUsMsgForm = new Form($sendUsMsgForm);
			sendUsMsgForm.init();
		}

		function PammDemoForm($form, options) {

			var self = this;

			PammMainDemoForm.apply(this, arguments);

			var parentFillForm = this.fillForm;

			var namePrefix,
			    namePrefixCapitalized;

			this.fillForm = function() {
				var $clientsContainer = this._$form.find('#clients-settings-container'),
				    $clientsTemplate = this._$form.find('.template-block').remove();

				for (var i = 1; i <= this.settings.meta.accounts.pamm.clientsCount; i++) {
					namePrefix = 'client' + i;
					namePrefixCapitalized = 'Client' + i;
					$clientsContainer.append(
						$clientsTemplate.clone()
							.removeClass('template-block')
							.find('h2').text(
								function(k, currText) {
									return currText.replace('%number%', i);
								})
								.end()
							.find('.currency').attr({
								id: 'pamm-demo-' + namePrefix + 'Currency',
								name: 'pamm' + namePrefixCapitalized + 'Currency',
								'data-balance-id': 'pamm-demo-' + namePrefix + 'Balance'
							})
								// .addClass('select')
								.chosen(chosenSettings)
								.end()
							.find('.balance').attr({
								id: 'pamm-demo-' + namePrefix + 'Balance',
								name: 'pamm' + namePrefixCapitalized + 'Balance'
							})
								// .addClass('select')
								.chosen(chosenSettings)
								.end()
					);
				}

				parentFillForm.call(this);
			}

			var parentFormatSettins = this.formatSettings;

			this.formatSettings = function() {
				parentFormatSettins.call(this);

				this.formatedSettings['pammCurrency'] = this.settings.meta.accounts.pamm['currencyList'];
				this.formatedSettings['pammCurrencyDef'] = this.settings.meta.accounts.pamm['defaultCurrency'];

				var clientsSelects = (clientsSelects = $form.data('clients-selects')) ? clientsSelects.split(',') : [];

				for (var i = 1; i <= this.settings.meta.accounts.pamm.clientsCount; i++) {
					$.each(clientsSelects, function(numb, clientSelect) {//balance,currency - depend on api settings (see "key + 'List'")
						namePrefix = 'client' + i;
						self.selects[namePrefix + clientSelect] = 'pamm-demo-' + namePrefix + clientSelect;
						self.formatedSettings[namePrefix + clientSelect] = self.settings.meta.accounts.pamm[clientSelect.toLowerCase() + 'List'];
						self.formatedSettings[namePrefix + clientSelect + 'Def'] = self.settings.meta.accounts.pamm['default' + clientSelect];

						if (clientSelect === 'Currency') {
							self.createCurrencyCallback(namePrefix);
						}
						// if (selectType == 'Balance' && self.settings.meta.multipliers.hasOwnProperty(self.settings.meta.accounts[accType]['defaultCurrency'])) {//we get default balance multiplied. To fill select with defaul fillSelects method with USD equivalent values and select correct default value we demultiply it
						// 	self.formatedSettings[accType + selectType + 'Def'] = self.formatedSettings[accType + selectType + 'Def'] / self.settings.meta.multipliers[self.settings.meta[accType]['defaultCurrency']];
						// } else if (selectType == 'Currency') {
						// 	self.formatedSettings[accType + selectType + 'Callback'] = accType + selectType + 'Callback';
						// 	callbacks[accType + selectType + 'Callback'] = function($currencySelect) {
						// 		self.currencySelects.push($currencySelect);
						// 		var $balanceSelect = $('#' + $currencySelect.data('balance-id'));

						// 		if ($balanceSelect.length) {
						// 			self.balanceSelects.push($balanceSelect);
						// 			self.formatBalances($balanceSelect, self.formatSettings[accType + 'CurrencyDef']);

						// 			$currencySelect.on('change', function() {
						// 				self.formatBalances($balanceSelect, $currencySelect.val());
						// 			});

						// 			$balanceSelect.on('change', function() {
										
						// 				if (!$balanceSelect.wasChangedByUser) {
						// 					$balanceSelect.wasChangedByUser = true;
						// 				}

						// 			});
						// 		}

						// 	}
						// }

					});
				}
			}
		}

		var $pammDemoForm = $('#pamm-demo-form');

		if ($pammDemoForm.length) {
			var pammDemoForm = new PammDemoForm($pammDemoForm, {
				setAutoFocus: true
			});

			pammDemoForm.init(function() {

				// pammDemoForm._$form.addClass('settings-got');//for progress bar

			});
		}

		function LpPammPopupCallbackForm($form, options) {
			Form.apply(this, arguments);

			var self = this;

			this.formatBalance = function($amountInput, currencySelectVal) {
				var coef = 1;

				if (self.settings.currencyMultipliers.hasOwnProperty(currencySelectVal)) {
					coef = self.settings.currencyMultipliers[currencySelectVal];
				}

				$amountInput.val($amountInput.data('value') * coef)
							.data('step', $amountInput.data('step-usd') * coef)
							.attr('min', $amountInput.data('min') * coef);
			}
		}

		var $lpPammPopupCallbackForm = $('#lp-pamm-popup-callback-form');

		if ($lpPammPopupCallbackForm.length) {
			var lpPammPopupCallbackForm = new LpPammPopupCallbackForm($lpPammPopupCallbackForm);
			lpPammPopupCallbackForm.init(function() {
				var $lpcCurrencySelect = lpPammPopupCallbackForm._$form.find('#lpc-currency'),
				    lpcCurrencySelectValue = $lpcCurrencySelect.val(),
					$amountInput = lpPammPopupCallbackForm._$form.find('#lpc-amount'),
					stepUSD,
					minUSD;
				
				if (lpPammPopupCallbackForm.settings.currencyMultipliers[lpcCurrencySelectValue]) {
					stepUSD = $amountInput.data('step') / lpPammPopupCallbackForm.settings.currencyMultipliers[lpcCurrencySelectValue];
					minUSD = $amountInput.attr('min') / lpPammPopupCallbackForm.settings.currencyMultipliers[lpcCurrencySelectValue];
				} else {
					stepUSD = $amountInput.data('step');
					minUSD = $amountInput.attr('min');
				}

				$amountInput.data({
					'step-usd': stepUSD,
					'min': minUSD
				});

				$lpcCurrencySelect.on('change', function() {
					lpPammPopupCallbackForm.formatBalance($amountInput, $lpcCurrencySelect.val());
				});
			});
		}

		var $selects = $('.select'),
		    chosenSettings = {
				width: '100%'
			};

		$selects.chosen(chosenSettings);

		// $selects.on('change', function() {//for autocomlete
		// 	$(this).trigger('chosen:updated');
		// });

		var callbacks = {};

		callbacks['countryCallback'] = function($select) {
			var self = this;
			var phoneCodeOptions = $select.find('option');
			formatPhoneCodes($select, phoneCodeOptions);

			function formatPhoneCodes($select, phoneCodeOptions) {
				phoneCodeOptions.each(function() {
					var $this = $(this);
					$this.html(formatPhoneCode($this.text()));
					$select.trigger('chosen:updated');
				});
			}
			function formatPhoneCode(text) {
				return '<span class="code">' + text.replace(/\+\d+\s/, '$&</span> <span class="country">') + '</span>';
			}
			function cutPhoneCode(str) {
				return str.match(/\+\d+/)[0];
			}

			var phoneCodeNumberInput = $('#' + $select.data('code-input-id'));

			setPhoneCode(getActiveOptionText($select), phoneCodeNumberInput);

			$select.on('change', function() {
				setPhoneCode(getActiveOptionText($select), phoneCodeNumberInput);
			});

			function setPhoneCode(str, phoneCodeNumberInput) {
				phoneCodeNumberInput.val(cutPhoneCode(str));
			}
			function getActiveOptionText($select) {
				return $select.children(':selected').text();
			}
		}

		callbacks['demoCountryCallback'] = function($select) {
			callbacks['countryCallback'].call(this, $select);
			var self = this;

			if (this.settings.meta.bannedCountryList) {
				var bannedText = this._$form.data('banned-text'),
				    japanText = this._$form.data('japan-text'),
				    $errorBlock = this._$form.find('#demo-top-error-block'),
				    $errorBlockText = $errorBlock.find('.text');

				$select.on('change', function() {
					var curVal = $select.val();
					if (self.settings.meta.bannedCountryList.indexOf(curVal) != -1) {

						if (curVal == 'JP') {
							$errorBlockText.html(japanText);
						} else {
							$errorBlockText.html(bannedText);
						}

						$errorBlock.slideDownFadeIn(transitionDuration*2);
					} else {

						if ($errorBlock.is(':visible')) {
							$errorBlock.fadeOutSlideUp(transitionDuration, function() {
								$errorBlockText.empty();
							});
						}

					}


					$.each(self.accTypes, function(numb, accType) {//adding balances and currencies to formatedSettings
							
						if (self.formatedSettings[accType + 'Currency'].indexOf(self.settings.meta.countries[curVal].currency) != -1) {
							self.currencySelects[numb].val(self.settings.meta.countries[curVal].currency).change();
						} else if (self.currencySelects[numb].val() !== self.formatedSettings[accType + 'CurrencyDef']) {//For the case when user changed country and one of the selects doesn't contain such currency
							self.currencySelects[numb].val('USD').change();

						}
						
					});

				});
			}

		}
	});

})(jQuery);